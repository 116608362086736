<template>
  <v-card flat>
    <v-card-title>
      Invoices from {{ dateFormatted(dateFrom) }}
      <template v-if="dateTo!==dateFrom"> to {{ dateFormatted(dateTo) }}</template>
    </v-card-title>
    <v-card-text>
      <v-row class="font-weight-bold text-center">
        <v-col>Total Quantity: {{ totalQuantity }}</v-col>
        <v-col>Total Value: {{ formatCurrency(totalValue) }}</v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-text-field :value="datesFormatted"
                    label="Select dates"
                    single-line
                    hide-details
                    readonly
                    @click="showDatePicker=true"
                    append-icon="mdi-calendar"
                    :loading="loading"
      />
      <v-spacer/>
      <v-text-field
          label="Search"
          v-model="search"
          single-line
          hide-details
          clearable
          append-icon="mdi-magnify"
      />
      <v-spacer/>
      <v-select
          label="tod/category"
          v-model="todFilter"
          single-line
          hide-details
          multiple
          :items="tods"
      />
      <v-select
          class="ml-2"
          label="order type"
          v-model="orderTypeFilter"
          single-line
          hide-details
          multiple
          :items="orderTypes"
      />
      <v-spacer/>
      <v-btn :disabled="!current" outlined @click="csvExport(dataAsTable,exportFilename())">Download CSV</v-btn>
      <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
      >
        <v-date-picker
            v-model="datePickerDate"
            :allowed-dates="isSunday"
            :range="isAdmin"
            no-title
            @close="!isAdmin && closeDatePicker()"
            @change="closeDatePicker"
        ></v-date-picker>
        <v-btn v-if="isAdmin" @click="closeDatePicker">Close</v-btn>
      </v-dialog>
    </v-card-actions>
    <v-card-text>
      <v-btn @click="showTable=!showTable">Show Table</v-btn>
      <v-data-table
          :class="showTable ? '' : 'd-none'"
          :items="invoiceItems"
          :headers="invoiceItemHeaders"
          :search="search"
          dense
          v-on:current-items="updateCurrent"
          disable-pagination
          hide-default-footer
          :loading="loading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import moment from "moment/moment";
import {csvAsTable, csvExport, dateFormatted, formatCurrency } from "@/store/utils";
import urlState from "@/router/urlState";

export default {
  name: "Invoices",
  props: {
    isAdmin: {type: Boolean, default: false, required: false},
    exportFile: {type: String, default: null, required: false}
  },
  mixins: [urlState],
  data() {
    return {
      invoices: null,
      dates: [],
      showDatePicker: null,
      search: null,
      datePickerDate: null,
      invoiceItemHeaders:
          'ship_date date meal_id description is_substitution uid first_name last_name plan stream order_type tod meal_size quantity unit_amount total'
              .split(' ')
              .map(i => ({value: i, text: i})),
      current: [],
      todFilter: null,
      orderTypeFilter: null,
      showTable: false,
      loading: false
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'todFilter', urlParam: 'tod', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'orderTypeFilter', urlParam: 'order_type', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'showTable', urlParam: 'table', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    return this.fetchData();
  },
  watch: {
    dates: 'fetchData',
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }

      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = [this.datePickerDate, (this.datePickerDate)];
        }
      }
    },
  },
  methods: {
    dateFormatted,
    formatCurrency,
    fetchData() {
      const {dateFrom, dateTo} = this;
      this.invoices = [];
      if (!dateFrom || !dateTo) {
        return;
      }
      this.loading = true;
      return api.get('v2/invoice', {params: {dateFrom, dateTo}})
          .then(r => {
            this.invoices = r.data;
            console.log('got', r.data);
          })
          .catch(e => console.error('fail', e))
          .finally(() => this.loading = false);
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    isSunday(d) {
      return moment(d).day() === 0
    },
    updateCurrent(current) {
      this.current = current;
    },
    csvExport,
    exportFilename() {
      if (this.exportFile) {
        return this.exportFile;
      }
      const parts = [...this.dates, this.search].filter(s => !!s);
      return `invoice-${parts.join('-')}`;
    },
  },
  computed: {
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    invoiceItemsUnfiltered() {
      return (this.invoices || []).flatMap(i => i.invoiceItems);
    },
    invoiceItems() {
      function isFiltered(filter, value) {
        return (filter && filter.length > 0 && !filter.includes(value))
      }

      return this.invoiceItemsUnfiltered
          .filter(i => {
            if (isFiltered(this.todFilter, i.tod)) {
              return false;
            }
            if (isFiltered(this.orderTypeFilter, i.order_type)) {
              return false;
            }
            return true;
          })
    },
    dataAsTable() {
      return csvAsTable(this.current, this.invoiceItemHeaders);
    },
    tods() {
      return [...new Set(this.invoiceItemsUnfiltered.map(i => i.tod))];
    },
    orderTypes() {
      return [...new Set(this.invoiceItemsUnfiltered.map(i => i.order_type))];
    },
    totalQuantity() {
      return this.current.map(c => c.quantity).reduce((i, sum) => i + sum, 0);
    },
    totalValue() {
      return this.current.map(c => c.total).reduce((i, sum) => i + sum, 0);
    }
  }
}
</script>


<style scoped>

</style>